<template>
  <div
    :class="{
      'validity-rule': true,
      even: uimodel.dataLevel === 0 || uimodel.dataLevel % 2 === 0,
      odd: uimodel.dataLevel % 2 !== 0,
    }"
    :id="'validity_rule_' + sequenceNumber"
    :data-test-id="'validity_rule_' + sequenceNumber"
    :draggable="!inherited"
    @dragstart.stop="startDrag"
    @dragend="dropDisabled = false"
  >
    <div class="validity-rule-properties">
      <div class="mr-3 font-weight-bold">{{ uimodel.sequenceNumber }}</div>
      <div class="d-flex flex-column flex-grow-1">
        <div class="d-flex flex-row">
          <DataEditor
            :uimodel="propertyModels.ruleType"
            :items="ruleTypes"
            :show-label="false"
            show-label-in-field
            immediate-update
            ref="ruleTypeInput"
            class="rule-type-select"
            @input="updateProperty('ruleType', $event)"
            @restore="
              restoreProperty(propertyModels.ruleType, validityRule.data)
            "
          />

          <WeekdaysEditor
            :uimodel="propertyModels.weekdays"
            @input="updateProperty('weekdays', $event)"
            @restore="
              restoreProperty(propertyModels.weekdays, validityRule.data)
            "
          />
        </div>
        <div v-if="ruleType" class="d-flex flex-row">
          <DataEditor
            v-if="ruleType === 'RESTRICTION_RULE'"
            :uimodel="propertyModels.operation"
            :show-label="false"
            :items="operations"
            show-label-in-field
            @input="updateProperty('operation', $event)"
            @restore="
              restoreProperty(propertyModels.operation, validityRule.data)
            "
          />
          <DataEditor
            :uimodel="propertyModels.fromDate"
            :show-label="false"
            :ext-input-rules="[
              inputRules.beforeUntilDate,
              inputRules.inParentTimeRange,
            ]"
            show-label-in-field
            @input="updateProperty('fromDate', $event)"
            @restore="
              restoreProperty(propertyModels.fromDate, validityRule.data)
            "
          />
          <DataEditor
            :uimodel="propertyModels.untilDate"
            :show-label="false"
            :ext-input-rules="[
              inputRules.afterFromDate,
              inputRules.inParentTimeRange,
            ]"
            show-label-in-field
            @input="updateProperty('untilDate', $event)"
            @restore="
              restoreProperty(propertyModels.untilDate, validityRule.data)
            "
          />
        </div>
      </div>
      <v-btn
        v-if="!inherited"
        icon
        color="primary"
        title="Add child validity rule"
        data-test-id="addChildRuleBtn"
        @click="addChildRule"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn
        v-if="!inherited"
        icon
        color="red"
        data-test-id="removeValidityRuleBtn"
        @click="removeValidityRule(validityRule)"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </div>
    <div class="validity-rule-children">
      <div
        v-for="(child, index) in children"
        :key="child.sequenceNumber + index"
        class="d-flex flex-column"
      >
        <div
          v-if="!dropDisabled"
          class="drop-zone"
          ref="dropZone"
          @drop="onDrop($event, child)"
          @dragover.prevent="highlightDropZone($event, true)"
          @dragenter.prevent="highlightDropZone($event, true)"
          @mouseleave="highlightDropZone($event, false)"
          @dragleave="highlightDropZone($event, false)"
          :data-test-id="'dropZoneBefore_' + child.sequenceNumber"
        >
          <div class="drop-zone-content">
            <v-icon left>mdi-download</v-icon>
            {{ dropZoneText }}
          </div>
        </div>

        <div class="d-flex flex-row justify-center">
          <v-icon>mdi-subdirectory-arrow-right</v-icon>
          <ValidityRule :uimodel="child" :parent-data="data" />
        </div>
      </div>
      <div
        v-if="!dropDisabled"
        class="drop-zone"
        ref="dropZone"
        @drop="onDrop"
        @dragover.prevent
        @dragenter.prevent="highlightDropZone($event, true)"
        @mouseleave="highlightDropZone($event, false)"
        @dragleave="highlightDropZone($event, false)"
        :data-test-id="'dropZoneChild_' + sequenceNumber"
      >
        <div class="drop-zone-content">
          <v-icon left>mdi-download</v-icon>
          {{ dropZoneText }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataEditorMixin from "../../../../../../mixins/data-editor-mixin";
import ValidityRuleMixin from "../../../../../../mixins/validity-rules-mixin";
import WeekdaysEditor from "./WeekdaysEditor";
export default {
  mixins: [DataEditorMixin, ValidityRuleMixin],
  name: "ValidityRule",
  components: {
    ValidityRule: () => import("./ValidityRule.vue"),
    DataEditor: () => import("../../DataEditor.vue"),
    WeekdaysEditor,
  },

  inject: [
    "addValidityRule",
    "updateValidityRule",
    "removeValidityRule",
    "moveValidityRule",
  ],
  props: {
    uimodel: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },

    parentData: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      validityRule: this.$cloneObject(this.uimodel) ?? {},
      data: this.$cloneObject(this.uimodel?.data) ?? {},
      dropDisabled: false,
    };
  },

  watch: {
    "uimodel.data": {
      handler: function (data) {
        this.data = data;
      },
      deep: true,
    },

    data: {
      handler: function (data) {
        if (data === undefined) return;
        this.$set(this.validityRule, "data", data);
        this.updateValidityRule(this.validityRule);
      },
      deep: true,
    },
  },

  methods: {
    addChildRule() {
      const index = this.getNextSequenceNumber(
        this.sequenceNumber,
        this.children
      );
      let rule = this.validityRuleProperties.reduce((rule, property) => {
        this.$set(rule, property, null);
        return rule;
      }, {});
      const sequenceNumber = this.sequenceNumber;
      this.$set(rule, "sequenceNumber", sequenceNumber + "_" + index);
      this.addValidityRule(rule);
    },

    updateProperty(property, value) {
      if (property === "ruleType") {
        this.data = {
          ...this.data,
          ruleType: value,
          operation: null,
        };
      } else {
        this.$set(this.data, property, value);
      }
    },

    startDrag(evt) {
      evt.dataTransfer.setData(
        "validityRule",
        JSON.stringify(this.validityRule)
      );

      // Create the ghost element
      const sequenceNumber = this.sequenceNumber;
      const ghost = document.createElement("div");
      ghost.classList.add("validity-rules-drag-ghost");
      ghost.innerHTML = sequenceNumber;
      ghost.id = "ghost_" + sequenceNumber;
      const appNode = document.getElementById("app");
      appNode.appendChild(ghost);
      evt.dataTransfer.setDragImage(ghost, 40, 30);
      this.dropDisabled = true;
    },

    onDrop(evt, nextSiblingRule) {
      if (this.dropDisabled) return;
      const dropData = evt.dataTransfer.getData("validityRule");
      if (dropData) {
        const validityRule = JSON.parse(dropData);
        //Remove pseudo drag ghost element
        const dragGhostNode = document.getElementById(
          "ghost_" + validityRule.sequenceNumber
        );
        dragGhostNode.remove();
        let sequenceNumber = nextSiblingRule?.sequenceNumber;
        if (!nextSiblingRule) {
          sequenceNumber =
            this.sequenceNumber +
            "_" +
            this.getNextSequenceNumber(this.sequenceNumber, this.children);
        }

        const parentOfDropRule = this.getParentSequenceNumber(
          validityRule.sequenceNumber
        );

        const oldRuleIndex = this.getRuleIndex(validityRule.sequenceNumber);
        const newRuleIndex = this.getRuleIndex(sequenceNumber);

        if (
          parentOfDropRule === this.sequenceNumber &&
          oldRuleIndex < newRuleIndex
        ) {
          //The rule is dropped in the same parent and its sequence number is now higher (e.g. 1_0 --> 1_1)
          sequenceNumber = this.sequenceNumber + "_" + (newRuleIndex - 1);
        }

        /* 
          Check if:
            1. The dropped rule is the FIRST child of the current validity rule and also dropped in FIRST place on the same rule
            2. The dropped rule is the LAST child of the current validity rule and dropped in LAST place on the same rule
          If either one of those is true just abort the drop
        */
        if (validityRule.sequenceNumber === nextSiblingRule?.sequenceNumber)
          return;
        else if (!nextSiblingRule && this.children.length > 0) {
          const lastChildRule = this.children[this.children.length - 1];
          if (validityRule.sequenceNumber === lastChildRule.sequenceNumber) {
            return;
          }
        }
        this.moveValidityRule(this.validityRule, sequenceNumber, validityRule);
      }
    },
  },

  computed: {
    sequenceNumber() {
      return this.validityRule.sequenceNumber;
    },

    propertyModels() {
      let propertyModels = this.buildSubUimodels(this.uimodel);
      this.$set(propertyModels.ruleType, "required", true);

      if (this.ruleType === "UNION_RULE") {
        this.$set(propertyModels.fromDate, "required", true);
        this.$set(propertyModels.untilDate, "required", true);
      } else if (this.ruleType === "RESTRICTION_RULE") {
        this.$set(propertyModels.operation, "required", true);
      }

      if (this.data) {
        Object.keys(propertyModels).forEach((key) => {
          const testId = "validityRule_" + this.sequenceNumber + "_" + key;
          this.$set(propertyModels[key], "testId", testId);
          this.$set(propertyModels[key], "data", this.data[key]);
        });
      }

      return propertyModels;
    },

    ruleTypes() {
      return [
        { value: "UNION_RULE", name: "UNION RULE" },
        { value: "RESTRICTION_RULE", name: "RESTRICTION RULE" },
      ];
    },

    ruleType() {
      return (
        this.validityRule?.data?.ruleType ||
        this.validityRule?.inheritedData?.ruleType
      );
    },

    operations() {
      return [
        { value: "INTERSECT", name: "INTERSECT" },
        { value: "SUBTRACT", name: "SUBTRACT" },
      ];
    },

    inputRules() {
      const validityRule = this.data;
      return {
        afterFromDate: (date) => {
          const fromDate = validityRule.fromDate;
          if (!fromDate) return true;
          const isAfter =
            !!date && this.$getDayDiff(new Date(fromDate), new Date(date)) >= 0;
          return (
            isAfter ||
            "Date must be after or equal to " +
              this.$getLocalizedDate(fromDate, {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              })
          );
        },
        beforeUntilDate: (date) => {
          const untilDate = validityRule.untilDate;
          if (!untilDate) return true;
          const isBefore =
            !!date &&
            this.$getDayDiff(new Date(date), new Date(untilDate)) >= 0;
          return (
            isBefore ||
            "Date must be before or equal to " +
              this.$getLocalizedDate(untilDate, {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              })
          );
        },

        inParentTimeRange: (date) => {
          const parentFromDate = this.parentData?.fromDate;
          const parentUntilDate = this.parentData?.untilDate;

          if (!parentFromDate || !parentUntilDate) {
            return true;
          }

          if (!date) return true;

          const isAfterFromDate =
            this.$getDayDiff(new Date(parentFromDate), new Date(date)) >= 0;
          const isBeforeUntilDate =
            this.$getDayDiff(new Date(date), new Date(parentUntilDate)) >= 0;

          return (
            (isAfterFromDate && isBeforeUntilDate) ||
            "Date must be within range of parent validity rule"
          );
        },
      };
    },

    inherited() {
      return this.uimodel.data === null || this.uimodel.data === undefined;
    },

    children() {
      return this.validityRule?.children ?? [];
    },

    dropZoneText() {
      return "Drop as child of rule " + this.sequenceNumber;
    },
  },
};
</script>
<style scoped>
.validity-rule {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 4px 8px;
  margin: 8px;
  border: 1px solid #ddd;
  border-radius: 6px;
}

.validity-rule::v-deep .field-wrap,
.validity-rule::v-deep
  .array.has-enum-items
  > .primitive-type-container
  > .enum-items-array-input,
.validity-rule::v-deep
  .array.has-enum-items
  > .primitive-type-container
  > .enum-items-array-input
  .field-wrap {
  width: 100%;
}

.validity-rule > .validity-rule-children {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  position: relative;
}

.validity-rule > .validity-rule-children > .validity-rule-child {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
}

.validity-rule > .validity-rule-properties {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.validity-rule > .validity-rule-properties .rule-type-select {
  flex-shrink: 2;
}

.validity-rule .drop-zone {
  padding: 4px;
}

.validity-rule .drop-zone.over {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 12px;
}

.validity-rule.even .drop-zone.over {
  background-color: rgba(0, 160, 227, 0.2);
  border: 2px dashed var(--v-psblue-base);
}

.validity-rule.odd .drop-zone.over {
  background-color: rgba(112, 183, 46, 0.2);
  border: 2px dashed var(--v-psgreen-base);
}

.validity-rule .drop-zone:not(.over) > .drop-zone-content {
  display: none;
}
</style>
<style>
.validity-rule:hover {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.validity-rules-drag-ghost {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 80px;
  position: absolute;
  top: 0;
  left: -500px;
  background-color: white;
  border: 2px dashed lightgrey;
  border-radius: 6px;
  z-index: 9999;
}
</style>