export default {

    computed: {

        validityRuleProperties() {
            return ["ruleType", "weekdays", "operation", "fromDate", "untilDate"];
        },

    },

    methods: {

        getParentSequenceNumber(sequenceNumber) {
            const idx = sequenceNumber.lastIndexOf("_");
            //Return null if the rule is on root level
            if (idx === -1) return null;
            return sequenceNumber.substring(0, idx);
        },

        getNextSequenceNumber(parentSequenceNumber, children) {
            return children.reduce((index, rule) => {
                let seqNr = "" + index;
                if (parentSequenceNumber !== null) {
                    seqNr = parentSequenceNumber + "_" + seqNr
                }
                if (seqNr === rule.sequenceNumber) return index + 1;
                return index;
            }, 1);
        },

        getRuleIndex(sequenceNumber) {
            const idx = sequenceNumber.lastIndexOf("_") + 1;
            const indexString = sequenceNumber.substring(idx);
            return Number(indexString);
        },

        ruleIndexExists(ruleIndex, parentSequenceNumber, array) {
            //This function checks if the give index already exists within
            //the array
            return array.some(({ sequenceNumber }) => {
                if (parentSequenceNumber === null) {
                    return sequenceNumber === "" + ruleIndex
                }
                return sequenceNumber === parentSequenceNumber + "_" + ruleIndex;
            });
        },

        getChildrenOfRule(parentSequenceNumber, rulesArray = [], onlyFirstLevel = false) {
            return rulesArray.filter(({ sequenceNumber }) => {

                if (parentSequenceNumber === null) {
                    //Parent is root
                    if (onlyFirstLevel) {
                        return sequenceNumber.indexOf("_") === -1;
                    }
                    return true;
                }

                const isChild =
                    sequenceNumber !== parentSequenceNumber &&
                    sequenceNumber.startsWith(parentSequenceNumber);

                if (onlyFirstLevel) {
                    const parentLevel = parentSequenceNumber.split("_").length - 1;
                    const currentLevel = sequenceNumber.split("_").length - 1;
                    return isChild && parentLevel + 1 === currentLevel;
                }

                return isChild;
            });
        },

        highlightDropZone(evt, isOver) {
            if (this.dropDisabled || this.inherited) return;
            const dropZone = evt.currentTarget;
            if (isOver) {
                dropZone.classList.add("over");
            } else {
                dropZone.classList.remove("over");
            }
        },
    }

}